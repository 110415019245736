/* imports and utilities like animations and mixins should be defined here */
@use '~@xpo-ltl/ngx-ltl-core/styles/variables';
@use '~@xpo-ltl/ngx-ltl-core/styles/reset';
@import './flexboxgrid.scss';

$app-actionBar-height: 40px;

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}

.ag-theme-material .ag-header-cell-menu-button .ag-icon-menu {
  // TODO: remove when xpo-core-ag-grid is updated
  height: 39px !important;
}

[mat-icon-button].mat-icon-button {
  height: auto;
}

.warning-message {
  color: #FFA600;
}

.success-message {
  color: #008000;
}

.error-message {
  color: #FF0000;
}

.full-width {
  width: 100%;
}

.xpo-Header-subAppName {
  font-size: 1.3rem !important;
}

.xpo-Header-items::before {
  content: none !important;
}

.table-responsive {
  display: block;
  overflow-x: auto;
  width: 100%;

  .mat-table {
    display: table;
    margin: 0;
    margin-bottom: 1rem;
    max-width: 100%;
    width: 100%;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell {
    display: table-cell;
    line-break: unset;
    overflow: hidden;
    padding: 0 5px;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    word-wrap: inherit;
  }
}

.xpo-BoardActions {
  .mat-button {
    padding: 5px 20px !important;
  }
}

.xpo-AppliedFilters {
  padding: 10px;
}

.xpo-FilterBar-content {
  height: 100%;
}

.xpo-AgGrid {
  .ag-paging-panel > * {
    white-space: nowrap;
  }

  .ag-cell {
    background-color: #FFF;
  }

}



@media (min-width: 360px) {
  .xpo-Header-subAppName {
    font-size: 1.714rem !important;
  }
}
